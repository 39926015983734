import React from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import ScrollToTop from 'react-scroll-up';
import ResetCss from './reset-css';
import { theme } from '../theme';
import Navbar from "../components/navbar/navbar";
import ScrollUpButton from "./scroll-up-button/scroll-up-button";
import {graphql, useStaticQuery} from "gatsby";
import Footer from "./footer/footer";
import {FooterDescription} from "./footer/footer.style";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {



    const  q = useStaticQuery(graphql`
    query {
         wordpressSiteMetadata {
    
      logo_title
     
      home
      description
      title
    
  }
  
   allWordpressPage {
    edges {
      node {
        title
        slug
      }
    }
  }
  
  }`);

    const data = q.wordpressSiteMetadata;
    const pages = q.allWordpressPage.edges;
  return (
    <ThemeProvider theme={theme}>
      <>
        <ResetCss />
        <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
         <Navbar siteProps={data}  />
        </Sticky>

        {children}
          <Footer>
              <FooterDescription>
                  <span>Copyright &copy; {new Date().getFullYear()}</span>
                  <a href="/">Home</a>

                  {pages.map(({ node }: any) => (
                        <a key={'footer'+node.slug} href={'/'+node.slug}>{node.title}</a>
                      ))
                  }

              </FooterDescription>
          </Footer>
        <ScrollToTop
          showUnder={300}
          duration={700}
          easing="easeInOutCubic"
          style={{ bottom: 30, right: 20 }}
        >
          <ScrollUpButton/>
        </ScrollToTop>
      </>
    </ThemeProvider>
  );
};

export default Layout;
