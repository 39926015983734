import React, { useState } from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import { IoIosSearch, IoIosClose } from 'react-icons/io';
import { DrawerProvider } from '../drawer/drawer-context';
import Menu from './menu';
import MobileMenu from './mobile-menu';
import SearchContainer from '../../containers/search/search';

import HeaderWrapper, {
  NavbarWrapper,
  Logo,
  MenuWrapper,
  NavSearchButton,
  NavSearchWrapper,
  SearchCloseButton,
  NavSearchFromWrapper, LogoText,
} from './navbar.style';
import { TagNode} from "../../../../interfaces/tag";
import {Siteprops} from "../../../../interfaces/siteprops";

type NavbarProps = {
  className?: string;
  siteProps:Siteprops;
};



const Navbar: React.FunctionComponent<NavbarProps> = ({
  className,
  ...props
}) => {
  const [state, setState] = useState({
    toggle: false,
    search: '',
  });

  const toggleHandle = () => {
    setState({
      ...state,
      toggle: !state.toggle,
    });
  };


  const menu = useStaticQuery(
      graphql`
      query categoryNavbar {
  
  allWordpressTag(sort: {order: DESC, fields: count},limit: 4) {
    edges {
      node {
        slug
        name
        wordpress_id
      }
    }
  }
}    `
  );

  const allItems = menu.allWordpressTag.edges as TagNode[];
  const currentMenu = [];

  for(let i=0;i<allItems.length;i++){

      const target = allItems[i].node;
      target.items = [];
      for(let k=0;k<allItems.length;k++){
        if(allItems[k].node.parentitem === target.wordpress_id){
          target.items.push(allItems[k].node);
        }
      }
      currentMenu.push(target);
  }


  // Add all classs to an array
  const addAllClasses = ['header'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <HeaderWrapper className={addAllClasses.join(' ')} {...props}>
      <NavbarWrapper className="navbar">
        <DrawerProvider>
          <MobileMenu items={currentMenu}  />
        </DrawerProvider>
        <Logo>
          <Link to="/">
            <LogoText>{props.siteProps.logo_title}</LogoText>
          </Link>
        </Logo>
        <MenuWrapper>
          <Menu items={currentMenu} />
        </MenuWrapper>
        <NavSearchButton
          type="button"
          aria-label="search"
          onClick={toggleHandle}
        >
          <IoIosSearch size="23px" />
        </NavSearchButton>
      </NavbarWrapper>

      <NavSearchWrapper className={state.toggle === true ? 'expand' : ''}>
        <NavSearchFromWrapper>
          <SearchContainer />
          <SearchCloseButton
            type="submit"
            aria-label="close"
            onClick={toggleHandle}
          >
            <IoIosClose />
          </SearchCloseButton>
        </NavSearchFromWrapper>
      </NavSearchWrapper>
    </HeaderWrapper>
  );
};

export default Navbar;
