import React from 'react';
import { Link } from 'gatsby';
import { MenuItemWrapper,MenuSubItemWrapper, MenuItem } from './navbar.style';
import {Tag} from "../../../../interfaces/tag";

type MenuProps = {
  items: Tag[];
  className?: string;
};

const Menu: React.FunctionComponent<MenuProps> = ({
  items,
  className,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['menu'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }


  const renderSubItems = (items:Tag[]) => {
    return items.map((item)=>(
        <MenuItem key={'submenu'+item.wid}>
          <Link to={`/categories/${item.slug}`}>{item.name}</Link>
        </MenuItem>
    ));
  }


  return (
    <MenuItemWrapper className={addAllClasses.join(' ')} {...props}>


      <MenuItem>
        <Link activeClassName="active-link" to="/">Home</Link>
      </MenuItem>

      {items.map((item, index) => (
        <MenuItem key={index}>
            <Link to={'/tags/'+item.slug} activeClassName="active-link">
              {item.name}
            </Link>
          { (typeof item.items!=='undefined' && item.items.length > 0) && <MenuSubItemWrapper>{renderSubItems(item.items)}</MenuSubItemWrapper>}
        </MenuItem>
      ))}
    </MenuItemWrapper>
  );
}
export default Menu;
